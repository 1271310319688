import { createRef } from 'react'
import type { GlobeMethods } from 'react-globe.gl'
import { ref } from 'valtio'

import type { Location } from '@acme/shared'
import { ValtioWrapper } from '@acme/shared'

import type { MapFeature } from '~/files/country_types'

interface ProjectBase {
  id: string
  locations: Location[]
}

interface TeamMemberBase {
  id: string
  locations: Location[]
}

export const store = new ValtioWrapper({
  globeMode: 'projects' as 'projects' | 'team',
  hoverHighCountry: false,
  hoveredFeature: null as MapFeature | null,
  globeSpins: true,
  globeRef: ref(createRef<GlobeMethods>()),
  teamCutoffRef: ref(createRef<HTMLDivElement>()),
  selectedTeamMemberFeature: null as {
    teamMember: TeamMemberBase
    feature: MapFeature
  } | null,
  selectedProjectFeature: null as {
    project: ProjectBase
    feature: MapFeature
  } | null,
})
